import React from 'react'
import FadeInSectionDownToUp from '../FadeAnimations/FadeInSectionDownToUp'
import { useNavigate } from 'react-router-dom'

export default function SchoolPrograms() {

    const navigate = useNavigate();

    return (
        <div className='bg-white dark:bg-black'>
            <div className='px-60 xl:px-60 sm:px-10 md:px-10 max-md:px-10 max-sm:px-10'>
                <FadeInSectionDownToUp>
                    <h1 className="text-9xl max-sm:text-6xl py-20 leading-[110px] from-purple-600 via-pink-600 to-blue-600 bg-gradient-to-b bg-clip-text text-transparent">
                        Our<br />Programs
                    </h1>
                </FadeInSectionDownToUp>
            </div>
            <div className='px-60 xl:px-60 sm:px-10 max-sm:px-10'>
                <FadeInSectionDownToUp>
                    <p className='text-black dark:text-white text-justify text-2xl max-sm:text-xl'>
                        Our thoughtfully crafted programs provide practical learning experiences across various fields, including Aeronautics and aerospace, AI and ML, and STEM and robotics. Explore our diverse range of customized in-school labs tailored to each program. Students will dive deep into these subjects, enjoying an exciting journey of discovery and creativity.
                    </p>
                </FadeInSectionDownToUp>
            </div>
            <div className='grid grid-cols-1 px-60 xl:px-60 sm:px-10 max-sm:px-10 py-20 space-y-3'>
                <FadeInSectionDownToUp>
                    <div className="relative rounded-xl left-1/2 w-1/2">
                        <a href='/aviation-and-aero-lab'>
                            <img
                                src="https://ik.imagekit.io/mhcockpit1/MHB/School%20Programs/Pic_1.jpg"
                                alt="Main Feature 1"
                                className="rounded-[50px]"
                            />
                            <div className="absolute overflow-hidden inset-0 flex flex-col pl-10 max-sm:pl-30 pt-0 max-sm:pt-12 bg-black bg-opacity-75 max-sm:bg-opacity-50 text-center text-white opacity-0 xl:opacity-0 sm:opacity-100 max-sm:opacity-100 hover:opacity-100 transition-opacity rounded-[50px]">

                                <h2 className="text-4xl max-sm:text-lg font-bold text-left mt-64 xl:mt-64 sm:mt-32 max-sm:mt-0 [word-spacing:-4px] ">
                                    Aviation & Aerospace.
                                </h2>
                                <button
                                    type="button"
                                    onClick={() => navigate('/aviation-and-aero-lab')}
                                    className="text-white w-40 max-sm:w-20 mt-3 bg-gradient-to-r from-violet-600 to-pink-600 hover:bg-gradient-to-r hover:from-pink-800  hover:to-violet-800 focus:outline-none  font-medium rounded-full text-sm px-10 max-sm:px-1 py-3.5 max-sm:py-1 text-center transition-all ease-in-out duration-200 shadow-lg"
                                >
                                    Read More
                                </button>
                                {/* <p className=" text-left text-[12px] w-56 mt-2">
                                    AR and VR at MH Intellect revolutionize experiences, blending reality with digital innovation for unparalleled learning, training, and interactive adventures.
                                </p> */}

                            </div>
                        </a>
                    </div>
                </FadeInSectionDownToUp>
                <FadeInSectionDownToUp>
                    <a href='/stem-and-robotics'>
                        <div className="relative rounded-xl">
                            <img
                                src="https://ik.imagekit.io/mhcockpit1/MHB/School%20Programs/Pic_2.jpg"
                                alt="Feature 1"
                                className="rounded-[50px]"
                            />
                            <div className="absolute overflow-hidden inset-0 flex flex-col pl-10 pt-0 max-sm:pt-12 bg-black bg-opacity-75 max-sm:bg-opacity-50 text-center text-white opacity-0 xl:opacity-0 sm:opacity-100 max-sm:opacity-100 hover:opacity-100 transition-opacity rounded-[50px]">

                                <h2 className="text-4xl max-sm:text-xl font-bold text-left mt-64 xl:mt-64 sm:mt-32 max-sm:mt-10  [word-spacing:-4px] ">
                                    STEM & Robotics.
                                </h2>
                                <button
                                    type="button"
                                    onClick={() => navigate('/stem-and-robotics')}
                                    className="text-white w-40 max-sm:w-20 mt-3 bg-gradient-to-r from-violet-600 to-pink-600 hover:bg-gradient-to-r hover:from-pink-800  hover:to-violet-800 focus:outline-none  font-medium rounded-full text-sm px-10 max-sm:px-1 py-3.5 max-sm:py-1 text-center transition-all ease-in-out duration-200 shadow-lg"
                                >
                                    Read More
                                </button>
                                {/* <p className=" text-left text-[12px] w-56 mt-2">
                                    AR and VR at MH Intellect revolutionize experiences, blending reality with digital innovation for unparalleled learning, training, and interactive adventures.
                                </p> */}

                            </div>
                        </div>
                    </a>
                </FadeInSectionDownToUp>
                <FadeInSectionDownToUp>
                    <div className="relative rounded-xl w-1/2">
                        <a href='/ai-and-ml'>
                            <img
                                src="https://ik.imagekit.io/mhcockpit1/MHB/School%20Programs/Pic_3.jpg"
                                alt="Feature 2"
                                className="rounded-[50px]"
                            />
                            <div className="absolute overflow-hidden inset-0 flex flex-col pl-10 pt-0 max-sm:pt-0 bg-black bg-opacity-75 max-sm:bg-opacity-50 text-center text-white opacity-0 xl:opacity-0 sm:opacity-100 max-sm:opacity-100 hover:opacity-100 transition-opacity rounded-[50px]">

                                <h2 className="text-4xl max-sm:text-lg font-bold text-left mt-64 xl:mt-64 sm:mt-32 max-sm:mt-20 [word-spacing:-4px]">
                                    AI & ML.
                                </h2>
                                <button
                                    type="button"
                                    onClick={() => navigate('/ai-and-ml')}
                                    className="text-white w-40 max-sm:w-20 mt-3 bg-gradient-to-r from-violet-600 to-pink-600 hover:bg-gradient-to-r hover:from-pink-800  hover:to-violet-800 focus:outline-none  font-medium rounded-full text-sm px-10 max-sm:px-1 py-3.5 max-sm:py-1 text-center transition-all ease-in-out duration-200 shadow-lg"
                                >
                                    Read More
                                </button>
                                {/* <p className=" text-left text-[12px] w-56 mt-2">
                                    AR and VR at MH Intellect revolutionize experiences, blending reality with digital innovation for unparalleled learning, training, and interactive adventures.
                                </p> */}

                            </div>
                        </a>
                    </div>
                </FadeInSectionDownToUp>
            </div>
        </div>
    )
}
